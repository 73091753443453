import React from 'react';
import { Router, navigate, RouteComponentProps } from '@reach/router';
import { useQuery } from '@apollo/react-hooks';

import { onError } from '../../utils';
import gqlQuery from './gql-query';

import LoadingPartial from '../Loading/LoadingPartial';
import NavLink from '../NavLink/NavLink';
import Consumers from './Consumers/Consumers';
import PaidyPayments from './PaidyPayments/PaidyPayments';
import CombiniPayments from './CombiniPayments/CombiniPayments';
import Card from '../Card/Card';
import { DuplicateSettlement } from './DuplicateSettlement/DuplicateSettlement';

type SearchPageProps = {
  searchKeyword: string;
  updateUIPairs: Function;
};

const RouterPage = (
  props: { pageComponent: JSX.Element } & RouteComponentProps
) => props.pageComponent;

/**
 * Check if the search result has only one result for the given type.
 */
const hasSingleResult = (typeTocheck: string, searchResult: any) => {
  const resultTypes = ['consumers', 'paidyPayments', 'combiniPayments'];

  const otherResultsAreEmpty = resultTypes
    .filter(type => type !== typeTocheck) // Exclude the current type
    .every(type => searchResult[type].length === 0);

  return searchResult[typeTocheck].length === 1 && otherResultsAreEmpty;
};

/**
 * Redirect to the exact match page if there is only one result,
 */
const redirectExactMatch = (searchResult: any) => {
  if (hasSingleResult('consumers', searchResult)) {
    const consumerId = searchResult.consumers[0].id;
    navigate(`/consumers/${consumerId}/details`);
    return null;
  }

  if (hasSingleResult('paidyPayments', searchResult)) {
    const paymentId = searchResult.paidyPayments[0].id;
    navigate(`/payments/${paymentId}/auth`);
    return null;
  }

  if (hasSingleResult('combiniPayments', searchResult)) {
    const combinedPaymentId = searchResult.combiniPayments[0].id;
    navigate(`/combini-payments/${combinedPaymentId}`);
    return null;
  }
};

const SearchPage = ({ searchKeyword, updateUIPairs }: SearchPageProps) => {
  if (!searchKeyword) {
    return (
      <Card>
        <div className="na-box" />
      </Card>
    );
  }

  // disabled because we want to avoid unnecessary request at first render
  // eslint-disable-next-line
  const { loading, data } = useQuery(gqlQuery, {
    onError,
    variables: { searchKeyword },
  });

  if (loading) {
    return <LoadingPartial />;
  }

  if (!data || !data.fuzzySearch) {
    return (
      <Card>
        <div className="na-box" />
      </Card>
    );
  }

  const {
    consumers,
    combiniPayments,
    paidyPayments,
    chargesByProviderRef,
  } = data.fuzzySearch;

  redirectExactMatch(data.fuzzySearch);

  return (
    <div>
      <Card>
        <nav className="tabs">
          <NavLink title="ペイメント" to="/search/payments">
            ペイメント
          </NavLink>
          <NavLink title="顧客名" to="/search/consumers">
            顧客名
          </NavLink>
          <NavLink title="コンビニ払い" to="/search/combini-payments">
            コンビニ払い
          </NavLink>
          <NavLink title="コンビニ払い" to="/search/duplicate-settlements">
            Suspicious Duplicate Settlement
          </NavLink>
        </nav>
        <Router primary={false}>
          <RouterPage
            path="payments"
            pageComponent={
              <PaidyPayments
                payments={paidyPayments}
                updateUIPairs={updateUIPairs}
              />
            }
          />
          <RouterPage
            path="consumers"
            pageComponent={
              <Consumers updateUIPairs={updateUIPairs} consumers={consumers} />
            }
          />
          <RouterPage
            path="combini-payments"
            pageComponent={
              <CombiniPayments
                updateUIPairs={updateUIPairs}
                combiniPayments={combiniPayments}
              />
            }
          />
          <RouterPage
            path="duplicate-settlements"
            pageComponent={
              <DuplicateSettlement duplicateCharges={chargesByProviderRef} />
            }
          />
        </Router>
      </Card>
    </div>
  );
};

export default SearchPage;
