import React from 'react';

import { Link } from '@reach/router';
import { chargesByProviderRefType } from '../../../types/searchResultType';
import { currencyFormatter, getTimeString } from '../../../utils';

type DuplicateSettlementProps = {
  duplicateCharges: chargesByProviderRefType;
};

export const DuplicateSettlement = ({
  duplicateCharges,
}: DuplicateSettlementProps) => {
  if (!duplicateCharges || !duplicateCharges.length) {
    return <div className="na-box" />;
  }

  return (
    <div className="table">
      <table>
        <thead>
          <tr>
            <th>Charge Id</th>
            <th>Consumer Id</th>
            <th>Order Reference</th>
            <th>Barcode Id</th>
            <th className="align-right">金額</th>
            <th>作成日</th>
          </tr>
        </thead>
        <tbody>
          {duplicateCharges.map((charge, index) => {
            return (
              <tr key={index}>
                <td className="id">{charge.chargeId}</td>
                <td className="id">
                  <Link to={`/consumers/${charge.consumerId}`}>
                    {charge.consumerId}
                  </Link>
                </td>
                <td>{charge.orderReference}</td>
                <td>{charge.barcodeId}</td>
                <td className="align-right" style={{ minWidth: 120 }}>
                  {!charge.amount && charge.amount !== 0
                    ? 'N/A'
                    : currencyFormatter.format(charge.amount)}
                </td>
                <td>
                  <time>{getTimeString(new Date(charge.createdAt))}</time>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
